import React, { useState, useEffect } from 'react'
import {
  StackProps,
  Stack,
  Card,
  Heading,
  IconButton,
  TextInput,
  Icon,
  Text,
  InputGroup,
  InputRightElement,
  CloseButton,
  LinkButton,
  Spinner,
  useStoreSearch,
  Skeleton,
  Box,
  Link,
  AddressInput,
  Button,
  Flex,
} from '@thirstycamel/ui'
import { useUser } from '../../hooks/useUser'
import { getStoreOpenCloseStatus } from '@ts/core/src/shared/services/time'
import type { StoreEntity } from '@ts/core/src/modules/store/store.entity'

import { useActions, useStore } from '../../store/hooks'
import { storeSearchPlaceholders } from '../../utils/placeholders'
import { config } from '../../utils/config'
import { useRouter } from 'next/router'
import TownInput from '@thirstycamel/ui/lib/components/AddressInput/TownInput'
/** TODO: Be more creative when naming components */
const TextAndIcon = ({ text, icon, ...restProps }) => (
  <Stack spacing={1} isInline align="center" {...restProps}>
    <Icon name={icon} fontSize="xs" color="blackAlpha.700" />
    <Text fontSize="xs" fontWeight="medium" color="blackAlpha.700">
      {text}
    </Text>
  </Stack>
)

///////////////////////////////////////////////////////////////////////////////////////////////////

export interface StorePickerSearchProps extends StackProps {
  closeButtonRef?: React.RefObject<HTMLElement>
  searchInputRef?: React.RefObject<HTMLElement>
}

export const StorePickerSearch = ({
  closeButtonRef,
  searchInputRef,
  ...restProps
}: StorePickerSearchProps) => {
  const [locationHint, setLocationHint] = useState<{ lat: number; lng: number; state: string }>(
    null,
  )
  const [isLoadingLocation, setIsLoadingLocation] = useState(false)
  const router = useRouter()
  const { isLoggedIn } = useUser()

  const { hidePopout, selectStore } = useActions(store => store.store)
  const { fetchProductLists } = useActions(actions => actions.navigation)
  const { updateUser } = useActions(actions => actions.auth)

  const {
    setSearchValue,
    searchValue,
    resolvedData,
    isFetching,
    refetch,
  } = useStoreSearch<StoreEntity>({
    limit: 3,
    lat: locationHint?.lat,
    lng: locationHint?.lng,
  })

  const { selectedStore, isPopoutOpen } = useStore(s => s.store)
  const location = useStore(s => s.location)

  const handleSelect = (store: StoreEntity) => {
    if (store.externalURL) {
      window.location.href = store.externalURL
    } else {
      selectStore(store)
      fetchProductLists(store?.region)
      hidePopout()
    }
  }

  const handleLocationSelect = () => {
    setIsLoadingLocation(true)

    if (navigator?.geolocation) {
      navigator.geolocation?.getCurrentPosition(
        function () {},
        function () {},
        {},
      )

      navigator.geolocation?.getCurrentPosition(
        position => {
          if (position?.coords?.latitude && position?.coords?.longitude) {
            setLocationHint({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              state: null,
            })

            if (isLoggedIn) {
              try {
                updateUser({
                  lat: position?.coords?.latitude,
                  lng: position?.coords?.longitude,
                })
              } catch (e) {
                console.log('e', { e })
              }
            }
          } else {
            setLocationHint(null)
          }

          setIsLoadingLocation(false)
        },
        error => {
          setIsLoadingLocation(false)
        },
        // { timeout: 60000, }
        { timeout: 60000, enableHighAccuracy: true },
      )
    } else {
      alert('Geolocation is not supported by this browser.')

      setIsLoadingLocation(false)
    }
  }

  /* WA redirect */
  useEffect(() => {
    if (locationHint?.state === 'WA') {
      window.location.href = 'https://wa.thirstycamel.com.au'
    }
  }, [locationHint?.state])

  useEffect(() => {
    if (searchValue && searchValue.length === 4 && searchValue.startsWith('6')) {
      window.location.href = 'https://wa.thirstycamel.com.au'
    }
  }, [searchValue])

  return (
    <Stack spacing={6} pt={3} pb={8} {...restProps}>
      <Stack spacing={4} flexShrink={0}>
        <Stack>
          {!selectedStore && (location.isFetching || location.region) && (
            <Stack spacing={1} alignItems="center" mb={5}>
              <Box>
                {location.isFetching ? (
                  <Skeleton h="1rem" w="8rem" />
                ) : (
                  <Text fontSize="sm" fontWeight="medium">
                    We think you're in <b>{location.region}</b>
                  </Text>
                )}
              </Box>
              <Box>
                <Text fontSize="xs" color="gray.500">
                  - for relevant products and pricing select your nearest Thirsty -
                </Text>
              </Box>
            </Stack>
          )}

          <Stack isInline justify="space-between" align="center">
            <Heading fontSize="xs" textTransform="uppercase" color="pink.500">
              Enter postcode or suburb:
            </Heading>

            <CloseButton
              ref={closeButtonRef}
              size="sm"
              color="pink.500"
              onClick={() => hidePopout()}
            />
          </Stack>

          <Box
            as="form"
            onSubmit={event => {
              event.preventDefault()
              refetch()
            }}
          >
            <TownInput
              ref={searchInputRef}
              onChangeValue={value => {
                if (typeof value !== 'string' && value && value.lat && value.lng) {
                  setLocationHint({ lat: value.lat, lng: value.lng, state: value.state })
                } else {
                  setLocationHint(null)
                }
              }}
              placeholder={storeSearchPlaceholders[location.region] || storeSearchPlaceholders.VIC}
              onChangeInputValue={value => {
                if (new RegExp('^\\d{4}$').test(value)) {
                  setSearchValue(value)
                } else {
                  setSearchValue(null)
                }
              }}
            />
          </Box>
        </Stack>

        {resolvedData && (locationHint || searchValue) && (
          <Stack spacing={4}>
            <Heading fontSize="xs">Set as your bottleshop:</Heading>

            {resolvedData.items.length === 0 ? (
              <Text>No stores found</Text>
            ) : (
              <Stack spacing={4}>
                {!isFetching ? (
                  resolvedData.items.map(store => (
                    <Card
                      key={store.slug}
                      as="button"
                      textAlign="left"
                      onClick={() => handleSelect(store)}
                      bg="gray.50"
                      flexShrink={0}
                    >
                      <Stack
                        isInline
                        spacing={4}
                        py={3}
                        px={4}
                        flex="1 1 0"
                        justify="space-between"
                      >
                        <Stack spacing={1}>
                          <Heading fontSize="xs" textTransform="uppercase" color="pink.500">
                            {store.name}
                          </Heading>

                          <Heading fontSize="xs">{getStoreOpenCloseStatus(store.hours)}</Heading>

                          <Stack isInline spacing={3}>
                            {store?.isDeliveryReady && <TextAndIcon text="Delivery" icon="truck" />}
                            {store?.isPickupReady && <TextAndIcon text="Pickup" icon="briefcase" />}
                          </Stack>
                        </Stack>

                        <Link
                          hrefAs={`/stores/${store.slug}`}
                          href={`/stores/[slug]`}
                          onClick={e => {
                            e.stopPropagation()
                            hidePopout()
                          }}
                        >
                          <IconButton
                            icon="info-outline"
                            variant="outline"
                            variantColor="pink"
                            fontSize="lg"
                            hasDepth
                          />
                        </Link>
                      </Stack>
                    </Card>
                  ))
                ) : (
                  <Stack spacing={4}>
                    <Skeleton height="72px" borderRadius="8px" />
                    <Skeleton height="72px" borderRadius="8px" />
                    <Skeleton height="72px" borderRadius="8px" />
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        )}
      </Stack>

      <LinkButton
        href={`/store-locator?backTo=${router.pathname}&backToAs=${router.asPath}`}
        hrefAs={`/store-locator?backTo=${router.pathname}&backToAs=${router.asPath}`}
        variant="outline"
        variantColor="pink"
        flexShrink={0}
      >
        Choose from map
      </LinkButton>

      {navigator?.geolocation && (
        <Button
          onClick={() => handleLocationSelect()}
          variant="outline"
          variantColor="pink"
          flexShrink={0}
          isLoading={isLoadingLocation || isFetching}
        >
          Use my location
        </Button>
      )}
    </Stack>
  )
}

export default StorePickerSearch
